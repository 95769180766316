<template>
    <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider
      :trigger="null"
      collapsible
      v-model="collapsed"
      width="200"
      style="background: #fff;box-shadow: 2px 0 8px 0 rgba(29,35,41,.05);"
    >
      <div class="logo" />
      <!-- 左侧菜单 -->
      <a-menu style="max-width: 200px" :defaultSelectedKeys="selectedKeys" :openKeys.sync="openKeys" mode="inline" @click="menuClick" >
        <a-menu-item key="user">
          <a-icon type="usergroup-add" />
          <span>用户管理</span>
        </a-menu-item>
        <a-menu-item key="order">
          <a-icon type="money-collect" />
          <span>订单管理</span>
        </a-menu-item>
        <a-menu-item key="analysis">
          <a-icon type="area-chart" />
          <span>运营统计</span>
        </a-menu-item>
      </a-menu>
      <!-- 左侧菜单end -->
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <!-- 头部菜单 -->
        <a-row justify="space-between" type="flex">
          <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="()=> collapsed = !collapsed"/>
          <a-dropdown :trigger="['hover']">
            <span class="antd-header-index-right">
              <a-avatar style="margin-right:8px" :size="24"
                src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
              />{{name}}
              <a-icon type="down" />
            </span>
            <a-menu slot="overlay">
              <a-menu-item key="101" @click="password">
                <a-icon type="setting" />修改密码
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="102" @click="logout">
                <a-icon type="logout"/>退出登录
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-row>
        <!-- 头部菜单end -->
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px', height:'calc(100vh - 64px - 69px - 48px)',overflowY:'auto' }">
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </a-layout-content>
      <a-layout-footer style="text-align: center;">
        Ant Design Vue ©2022 Created by Square
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  created(){
    if(this.$route.name == 'baijiaxing' || this.$route.name == 'baijiaming'){
      this.openKeys = ['sub1']
    }
    this.selectedKeys.push(this.$route.name)
  },
  mounted(){
    this.name = sessionStorage.getItem('name');
  },
  data() {
    return {
      collapsed: false,
      selectedKeys: [],
      openKeys: [],
      name: '',
    };
  },
  methods: {
    menuClick(e){
      this.$router.push({ name: e.key})
    },
    logout(){
      const token = sessionStorage.getItem('token');
      sessionStorage.removeItem('token')
      this.$http.get("/service/login/logout",{params: {token: token}})
      this.$router.push({ name: 'login'})
    },
    password(){
      this.$router.push({ name: 'password'})
    }
  },
};
</script>
<style scoped lang="scss">
#components-layout-demo-custom-trigger {
  .trigger:hover {
    color: #1890ff;
  }
  .trigger {
    font-size: 18px !important;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.ant-menu-inline,
.ant-menu-vertical {
  border-right: none;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.antd-header-index-right {
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}
</style>